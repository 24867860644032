import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import clsx from 'clsx';
import { Collapse, Button } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import WorkTwoToneIcon from '@material-ui/icons/WorkTwoTone';
import MessageTwoToneIcon from '@material-ui/icons/MessageTwoTone';
import AlarmOnTwoToneIcon from '@material-ui/icons/AlarmOnTwoTone';
import PhoneAndroidTwoToneIcon from '@material-ui/icons/PhoneAndroidTwoTone';
import RecentActorsTwoToneIcon from '@material-ui/icons/RecentActorsTwoTone';
import CommuteTwoToneIcon from '@material-ui/icons/CommuteTwoTone';
import PhoneCallbackTwoToneIcon from '@material-ui/icons/PhoneCallbackTwoTone';
import EmojiPeopleTwoToneIcon from '@material-ui/icons/EmojiPeopleTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import EmojiTransportationTwoToneIcon from '@material-ui/icons/EmojiTransportationTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';
import { Link } from 'gatsby';

import { logout, getLoginUser } from 'services/loginService';

const SidebarMenu = props => {
  const { pageName } = props;
  const [isUpdate, setUpdate] = useState(false);

  const [openSettingModules, setOpenSettingModules] = useState(pageName !== 'SuperSetting' && pageName.includes('Setting'));
  const toggleSettingModules = event => {
    setOpenSettingModules(!openSettingModules);
    event.preventDefault();
  };

  const [openVehicleInspectionModules, setOpenVehicleInspectionModules] = useState(pageName.includes('VehicleInspection'));
  const toggleVehicleInspectionModules = event => {
    setOpenVehicleInspectionModules(!openVehicleInspectionModules);
    event.preventDefault();
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  }

  if (typeof window === 'undefined' || !getLoginUser().role) {
    return <></>
  }

  const dbname = getLoginUser().dbname;
  const roles = getLoginUser().role.split(';');
  const isSuperManager = roles[0] === 'supervisor';
  const managerAccess = roles[1]?.split(',') || [];

  return (
    <PerfectScrollbar>
      <div className="sidebar-navigation">
        <ul>
          {(isSuperManager || managerAccess.includes('Jobs')) && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/jobs/">
              <span className="sidebar-icon">
                <WorkTwoToneIcon fontSize="medium" />
              </span>
              Job Records
            </Link>
          </li>
          }
          {false &&
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/messages/">
              <span className="sidebar-icon">
                <MessageTwoToneIcon fontSize="medium" />
              </span>
              Messaging
            </Link>
          </li>
          }
          {false &&
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/driver-sessions/">
              <span className="sidebar-icon">
                <AlarmOnTwoToneIcon fontSize="medium" />
              </span>
              Book Out
            </Link>
          </li>
          }
          {(isSuperManager || managerAccess.includes('Devices')) && 
            <li>
              <Link activeClassName="active" className="nav-link-simple" to="/manage/devices/">
                <span className="sidebar-icon">
                  <PhoneAndroidTwoToneIcon fontSize="medium" />
                </span>
                Approved Devices
              </Link>
            </li>
          }
          {(isSuperManager || managerAccess.includes('Drivers')) && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/drivers/">
              <span className="sidebar-icon">
                <RecentActorsTwoToneIcon fontSize="medium" />
              </span>
              Drivers
            </Link>
          </li>
          }
          {(isSuperManager || managerAccess.includes('Vehicles')) && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/vehicles/">
              <span className="sidebar-icon">
                <CommuteTwoToneIcon fontSize="medium" />
              </span>
              Vehicles
            </Link>
          </li>
          }
          {false &&
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/call_takers/">
              <span className="sidebar-icon">
                <PhoneCallbackTwoToneIcon fontSize="medium" />
              </span>
              Call Takers
            </Link>
          </li>
          }
          {(isSuperManager || managerAccess.includes('Passengers')) && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/passengers/">
              <span className="sidebar-icon">
                <EmojiPeopleTwoToneIcon fontSize="medium" />
              </span>
              Passengers
            </Link>
          </li>
          }
          {(isSuperManager || managerAccess.includes('Accounts')) && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/accounts/">
              <span className="sidebar-icon">
                <AccountBalanceTwoToneIcon fontSize="medium" />
              </span>
              Accounts
            </Link>
          </li>
          }
          {(isSuperManager || managerAccess.includes('Invoices')) && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/invoices/">
              <span className="sidebar-icon">
                <MonetizationOnTwoToneIcon fontSize="medium" />
              </span>
              Invoices
            </Link>
          </li>
          }
          {(isSuperManager || managerAccess.includes('BlockedLocations')) && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/blocked-locations/">
              <span className="sidebar-icon">
                <BlockTwoToneIcon fontSize="medium" />
              </span>
              Blocked Locations
            </Link>
          </li>
          }
          {false &&
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/departments/">
              <span className="sidebar-icon">
                <FolderOpenTwoToneIcon fontSize="medium" />
              </span>
              Extras
            </Link>
          </li>
          }
          {(isSuperManager || managerAccess.includes('Settings')) && 
          <li>
            <a
              href="#/"
              onClick={toggleSettingModules}
              className={clsx({ active: openSettingModules })}
            >
              <span className="sidebar-icon">
                <SettingsTwoToneIcon fontSize="medium" />
              </span>
              <span className="sidebar-item-label">Settings</span>
              <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon />
              </span>
            </a>
            <Collapse in={openSettingModules}>
              <ul>
                <li>
                  <Link activeClassName="active" to="/manage/settings/information/">
                    Information
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/manage/settings/features/">
                    Features/Addons
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/manage/settings/communication/">
                    Communication
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/manage/settings/stripe/bills/">
                    Billing
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/manage/settings/stripe/account/">
                    Account
                  </Link>
                </li>
                {false &&
                <li>
                  <Link activeClassName="active" to="/manage/settings/drivers/">
                    Driver
                  </Link>
                </li>
                }
                <li>
                  <Link activeClassName="active" to="/manage/settings/fares/">
                    Fares
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/manage/settings/bookout/">
                    Book Out
                  </Link>
                </li>
                {false &&
                <li>
                  <Link activeClassName="active" to="/manage/settings/dispatch/">
                    Dispatch
                  </Link>
                </li>
                }
                <li>
                  <Link activeClassName="active" to="/manage/settings/card/">
                    Card/Account Processing
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          }
          {(isSuperManager || managerAccess.includes('VehicleInspection')) && 
          <li>
            <a
              href="#/"
              onClick={toggleVehicleInspectionModules}
              className={clsx({ active: openVehicleInspectionModules })}
            >
              <span className="sidebar-icon">
                <EmojiTransportationTwoToneIcon fontSize="medium" />
              </span>
              <span className="sidebar-item-label">Vehicle Inspection</span>
              <span className="sidebar-icon-indicator">
                <ChevronRightTwoToneIcon />
              </span>
            </a>
            <Collapse in={openVehicleInspectionModules}>
              <ul>
                <li>
                  <Link activeClassName="active" to="/manage/vehicle_inspection/question/">
                    Questionnaire
                  </Link>
                </li>
                <li>
                  <Link activeClassName="active" to="/manage/vehicle_inspection/history/">
                    Answer History
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          }
          {isSuperManager && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/managers/">
              <span className="sidebar-icon">
                <PeopleAltTwoToneIcon fontSize="medium" />
              </span>
              Portal Users
            </Link>
          </li>
          }
          {isSuperManager && (dbname === 'omngateway2') && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/supersetting/">
              <span className="sidebar-icon">
                <HelpTwoToneIcon fontSize="medium" />
              </span>
              Super Settings
            </Link>
          </li>
          }
          {false && 
          <li>
            <Link activeClassName="active" className="nav-link-simple" to="/manage/support/">
              <span className="sidebar-icon">
                <HelpTwoToneIcon fontSize="medium" />
              </span>
              Support
            </Link>
          </li>
          }
          <li>
            <a className="nav-link-simple" href="#" onClick={handleLogout}>
              <span className="sidebar-icon">
                <ExitToAppTwoToneIcon fontSize="medium" />
              </span>
              Logout
            </a>
          </li>
        </ul>
      </div>
    </PerfectScrollbar>
  );
};

export default SidebarMenu;
