import React, { useContext } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Tooltip } from '@material-ui/core';

import { getLoginUser } from 'services/loginService';

import { GlobalState } from '../../../../context/GlobalStateProvider';
import { Link } from 'gatsby';
import { PergoLogo } from '../../../../images';

const SidebarHeader = props => {
  const {
    sidebarToggle,
    setSidebarToggle,
    sidebarToggleMobile,
    setSidebarToggleMobile,
  } = useContext(GlobalState);

  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle);
  };
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  return (
    <>
      <div className="app-sidebar--header">
        <div className="app-sidebar-logo">
          <Link
            to="/"
            title="Pergo Portal"
            className="app-sidebar-logo"
          >
            {/* <div className="app-sidebar-logo--icon"> */}
            <img alt="Pergo Portal" src={PergoLogo} />
            {/* </div> */}
            <div className="app-sidebar-logo--text">
              <span>Management</span>

              <b>{getLoginUser().setting?.base_name || 'Pergo'}</b>
            </div>
          </Link>
        </div>
        <Tooltip title="Collapse sidebar" placement="right" arrow>
          <Button onClick={() => toggleSidebar()} className="btn btn-sm collapse-sidebar-btn">
            <FontAwesomeIcon icon={['far', 'dot-circle']} size="lg" />
          </Button>
        </Tooltip>
        <Button
          className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
            'is-active': sidebarToggleMobile,
          })}
          onClick={toggleSidebarMobile}
        >
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
        <Tooltip title="Expand sidebar" placement="right" arrow>
          <Button onClick={() => toggleSidebar()} className="expand-sidebar-btn btn btn-sm">
            <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
          </Button>
        </Tooltip>
      </div>
    </>
  );
};

export default SidebarHeader;
