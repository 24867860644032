import React from 'react';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';

import { isLoggedIn, isDisabledLoggedIn, getLoginUser } from 'services/loginService';
import ManageLayout from 'layouts/manageLayout';
import GlobalStateProvider from 'context/GlobalStateProvider';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import NotificationOverlay from 'components/NotificationOverlay';

const ManageTemplate = props => {
  const { pageName } = props.pageContext;
  let PageContent = null;

  if (!isLoggedIn() && typeof window !== 'undefined') {
    navigate('/login');
    return null;
  }

  // if (isDisabledLoggedIn() && pageName !== 'SettingBills') {
  //   navigate('/manage/settings/bills/');
  //   return null;
  // }

  if (typeof window === 'undefined' || !getLoginUser().role) {
    return <></>
  }

  const roles = getLoginUser().role.split(';');
  const managerAccess = roles[1]?.split(',') || [];
  if (roles[0] === 'manager' && !managerAccess.includes(pageName.startsWith('Settings') ? 'Settings' : pageName)) {
    return (
      <h3 style={{ padding: 20 }}>You don't have permission to access this page</h3>
    )
  }

  switch (pageName) {
    case 'Jobs':
      PageContent = loadable(() => import('./Jobs'));
      break;
    case 'Messages':
      PageContent = loadable(() => import('./Messages'));
      break;
    case 'DriverSessions':
      PageContent = loadable(() => import('./DriverSessions'));
      break;
    case 'Devices':
      PageContent = loadable(() => import('./Devices'));
      break;
    case 'Drivers':
      PageContent = loadable(() => import('./Drivers'));
      break;
    case 'Vehicles':
      PageContent = loadable(() => import('./Vehicles'));
      break;
    case 'CallTakers':
      PageContent = loadable(() => import('./CallTakers'));
      break;
    case 'Passengers':
      PageContent = loadable(() => import('./Passengers'));
      break;
    case 'BlockedLocations':
      PageContent = loadable(() => import('./BlockedLocations'));
      break;
    case 'Accounts':
      PageContent = loadable(() => import('./Accounts'));
      break;
    case 'AccountsView':
      PageContent = loadable(() => import('./Accounts/view'));
      break;
    case 'Invoices':
      PageContent = loadable(() => import('./Invoices'));
      break;
    case 'Departments':
      PageContent = loadable(() => import('./Departments'));
      break;
    case 'SettingInformation':
      PageContent = loadable(() => import('./Settings/Information'));
      break;
    case 'SettingFeatures':
      PageContent = loadable(() => import('./Settings/Features'));
      break;
    case 'SettingDrivers':
      PageContent = loadable(() => import('./Settings/Drivers'));
      break;
    case 'SettingFares':
      PageContent = loadable(() => import('./Settings/Fares'));
      break;
    case 'SettingBookOut':
      PageContent = loadable(() => import('./Settings/BookOut'));
      break;
    case 'SettingDispatch':
      PageContent = loadable(() => import('./Settings/Dispatch'));
      break;
    case 'SettingCard':
      PageContent = loadable(() => import('./Settings/Card'));
      break;
    case 'SettingCommunication':
      PageContent = loadable(() => import('./Settings/Communication'));
      break;
    case 'SettingBills':
      PageContent = loadable(() => import('./Settings/Bills'));
      break;
    case 'SettingStripeBills':
      PageContent = loadable(() => import('./Settings/Stripe Bills'));
      break;
    case 'SettingAccount':
      PageContent = loadable(() => import('./Settings/account'));
      break;
    case 'VehicleInspectionQuestion':
      PageContent = loadable(() => import('./VehicleInspection/Question'));
      break;
    case 'VehicleInspectionHistory':
      PageContent = loadable(() => import('./VehicleInspection/History'));
      break;
    case 'Managers':
      PageContent = loadable(() => import('./Managers'));
      break;
    case 'SuperSetting':
      PageContent = loadable(() => import('./SuperSetting'));
      break;
    case 'Support':
      PageContent = loadable(() => import('./Support'));
      break;
    default:
      PageContent = loadable(() => import('./Jobs'));
      break;
  }

  if (!PageContent) {
    return null;
  }

  return (
    <GlobalStateProvider>
      <CircularProgressOverlay>
        <NotificationOverlay>
          <ManageLayout pageName={pageName}>
            <PageContent {...props} />
          </ManageLayout>
        </NotificationOverlay>
      </CircularProgressOverlay>
    </GlobalStateProvider>
  );
};

export default ManageTemplate;
