import React, { useContext } from 'react';
import clsx from 'clsx';

import {
  Button,
} from '@material-ui/core';

import WorkTwoToneIcon from '@material-ui/icons/WorkTwoTone';
import MessageTwoToneIcon from '@material-ui/icons/MessageTwoTone';
import AlarmOnTwoToneIcon from '@material-ui/icons/AlarmOnTwoTone';
import PhoneAndroidTwoToneIcon from '@material-ui/icons/PhoneAndroidTwoTone';
import RecentActorsTwoToneIcon from '@material-ui/icons/RecentActorsTwoTone';
import CommuteTwoToneIcon from '@material-ui/icons/CommuteTwoTone';
import PhoneCallbackTwoToneIcon from '@material-ui/icons/PhoneCallbackTwoTone';
import EmojiPeopleTwoToneIcon from '@material-ui/icons/EmojiPeopleTwoTone';
import AccountBalanceTwoToneIcon from '@material-ui/icons/AccountBalanceTwoTone';
import MonetizationOnTwoToneIcon from '@material-ui/icons/MonetizationOnTwoTone';
import FolderOpenTwoToneIcon from '@material-ui/icons/FolderOpenTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import HelpTwoToneIcon from '@material-ui/icons/HelpTwoTone';
import EmojiTransportationTwoToneIcon from '@material-ui/icons/EmojiTransportationTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import AddTwoToneIcon from '@material-ui/icons/AddTwoTone';
import BlockTwoToneIcon from '@material-ui/icons/BlockTwoTone';

import { GlobalState } from 'context/GlobalStateProvider';
import { AppHeaderContainer } from './style';

const Header = (props) => {
  const { pageName } = props;
  const {sidebarToggleMobile, setSidebarToggleMobile} = useContext(GlobalState);

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  let title, titleIcon;
  switch (pageName) {
    case 'Jobs':
      title = 'Job Records';
      titleIcon = <WorkTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Messages':
      title = 'Messages';
      titleIcon = <MessageTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'DriverSessions':
      title = 'Book Out';
      titleIcon = <MessageTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Devices':
      title = 'Approved Devices';
      titleIcon = <PhoneAndroidTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Drivers':
      title = 'Drivers';
      titleIcon = <RecentActorsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Vehicles':
      title = 'Vehicles';
      titleIcon = <CommuteTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'CallTakers':
      title = 'Call Takers';
      titleIcon = <PhoneCallbackTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Passengers':
      title = 'Passengers';
      titleIcon = <EmojiPeopleTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Accounts':
      title = 'Accounts';
      titleIcon = <AccountBalanceTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'AccountsView':
      title = 'Account Detail';
      titleIcon = <AccountBalanceTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Invoices':
      title = 'Invoices';
      titleIcon = <MonetizationOnTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'BlockedLocations':
      title = 'Blocked Locations';
      titleIcon = <BlockTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Departments':
      title = 'Departments';
      titleIcon = <FolderOpenTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingInformation':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingFeatures':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingDrivers':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingFares':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingDispatch':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingCard':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingBills':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingStripeBills':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingAccount':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SettingBookOut':
      title = 'Settings';
      titleIcon = <SettingsTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'VehicleInspectionQuestion':
    case 'VehicleInspectionHistory':
      title = 'Vehicle Inspection';
      titleIcon = <EmojiTransportationTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Managers':
      title = 'Portal Users';
      titleIcon = <PeopleAltTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'SuperSetting':
      title = 'Supper Settings';
      titleIcon = <HelpTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    case 'Support':
      title = 'Support';
      titleIcon = <HelpTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
    default:
      title = 'Job Records';
      titleIcon = <WorkTwoToneIcon style={{ fontSize: 30 }}/>;
      break;
  }

  return (
    <AppHeaderContainer className={'app-header'}>
      <Button
          className={clsx(
            'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
            { 'is-active': sidebarToggleMobile }
          )}
          onClick={toggleSidebarMobile}>
          <span className="hamburger-box">
            <span className="hamburger-inner" />
          </span>
        </Button>
      <div className="app-header--header-left">
        {titleIcon}
      </div>
      <div className="app-header--header-center">
        <h4 className="app-header--internal-name">{title}</h4>
      </div>
      <div className="app-header--header-right">
        
      </div>
    </AppHeaderContainer>
  );
};

export default Header;
